// import request from '@/utils/request';
import * as FetchAPI from '@/components/fetchService';

interface IsignUpParams {
  name: string;
  email: string;
  password: string;
  company: string;
}

export async function signup(params: IsignUpParams): Promise<any> {
  let response;
  await fetch('/auth_management/signup/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    body: JSON.stringify(params),
  }).then(r => {
    response = r;
  });
  return response;
  /* return request('/auth_management/signup/', {
    method: 'POST',
    data: params,
  }); */
  // return FetchAPI.fetchAPIPOST('/auth_management/signup/', params);
}

export interface IsigninParams {
  email: string;
  password: string;
}

export async function signin(params: IsigninParams): Promise<any> {
  // return request('/auth_management/signin/', {
  //   method: 'POST',
  //   data: params,
  // });
  return FetchAPI.fetchAPIPOST('/auth_management/signin/', params);
}

export async function logoutFromServer(): Promise<any> {
  // return request('/auth_management/logout/', { method: 'POST' });
  return FetchAPI.fetchAPIPOST('/auth_management/logout/', {});
}

export async function queryCurrent(): Promise<any> {
  // return request('/auth_management/currentUser/');
  return FetchAPI.fetchAPIGET('/auth_management/currentUser/');
}

export interface IResetPasswordParams {
  email: string;
}

export async function requestResetPassword(params: IResetPasswordParams): Promise<any> {
  // return request('/auth_management/password/reset/', {
  //   method: 'POST',
  //   data: params,
  // });
  return FetchAPI.fetchAPIPOST('/auth_management/password/reset/', params);
}

export async function requestconfirmResetPassword(params: IResetPasswordParams): Promise<any> {
  const baseurl = '/auth_management/password/reset/confirm/';
  const { uid } = params;
  let { token } = params;

  if (token[token.length - 1] === '.') {
    token = token.substring(0, token.length - 1);
  }
  // Token's last character is a dot, removing which makes the HTTP post submission work properly
  params.token = token;

  const acturl = `${baseurl + uid}/${token}/`;
  return FetchAPI.fetchAPIPOST(acturl, params);
}
