import Cookies from 'universal-cookie';
import { utilSetLocalStorage, utilGetLocalStorage } from './storage_utils';
// use localStorage to store the authority info, which might be sent from server in actual project.

const cookies = new Cookies();

export function getAuthority(str?: string): string | string[] {
  // return  utilGetLocalStorage('antd-pro-authority') || ['admin', 'user'];
  const authorityString =
    typeof str === 'undefined' ? utilGetLocalStorage('antd-pro-authority') : str;
  // authorityString could be admin, "admin", ["admin"]
  let authority;
  try {
    if (authorityString) {
      authority = JSON.parse(authorityString);
    }
  } catch (e) {
    authority = authorityString;
  }
  if (typeof authority === 'string') {
    return [authority];
  }
  return authority;
}

export function setAuthority(authority: string | string[]): void {
  const proAuthority = typeof authority === 'string' ? [authority] : authority;
  return localStorage.setItem('antd-pro-authority', JSON.stringify(proAuthority));
}

export function getToken(): string | null {
  const tokenString = utilGetLocalStorage('token') || 'null';
  const token = JSON.parse(tokenString);
  return token;
}

export function setToken(token: string | null): void {
  // return localStorage.setItem('token', JSON.stringify(token));
  const hours: number = 10;
  const timeSec: number = hours * 60 * 60;
  cookies.set('token', token, { path: '/', maxAge: timeSec });
  return utilSetLocalStorage('token', JSON.stringify(token));
}
