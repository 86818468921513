import * as fetchAPI from '@/components/fetchService';
import * as constants from '@/constants';
import { cartDocParams, PurchaseBundleParams, downloadParams } from '@/components/data';
import { utilSetLocalStorage, utilGetLocalStorage } from '@/utils/storage_utils';

// Cart APIs
const url = '/inspections/api/payment_checkout';
const subscriptionURL = '/inspections/api/payment_user_subscription';

// Mail Notification Service API
const mailNotifierURL = 'https://codecarbonhq-regiwiz-backend.azurewebsites.net/mail/fdalytics/request483'
const mailNotifierURLRequestEir = 'https://codecarbonhq-regiwiz-backend.azurewebsites.net/mail/fdalytics/requesteir'

export function listCart() {
  const listParams = {
    operation: '',
    operation_param: {
      email_address: utilGetLocalStorage('User'),
      // dev:  utilGetLocalStorage('isLocal') === 'true',
    },
  };
  listParams.operation = 'list_cart_contents';
  return fetchAPI.fetchAPIPOST(url, listParams);
}

export function addToCart(params: cartDocParams) {
  const addParams = {
    operation: '',
    operation_param: {
      email_address: utilGetLocalStorage('User'),
      // dev:  utilGetLocalStorage('isLocal') === 'true',
    },
  };
  addParams.operation = 'add_to_cart';
  addParams.operation_param.document_id = params.document_id;
  addParams.operation_param.document_name = params.document_name;
  addParams.operation_param.document_type = params.document_type;

  if (params.document_type !== 'document_type_investigator_profile') {
    addParams.operation_param.inspection_id = params.inspection_id;
  }

  return fetchAPI.fetchAPIPOST(url, addParams);
}

export function deleteFromCart(params: cartDocParams) {
  const deleteParams = {
    operation: '',
    operation_param: {
      email_address: utilGetLocalStorage('User'),
      // dev:  utilGetLocalStorage('isLocal') === 'true',
    },
  };
  deleteParams.operation = 'delete_from_cart';
  deleteParams.operation_param.document_id = params.document_id;
  deleteParams.operation_param.document_name = params.document_name;
  deleteParams.operation_param.document_type = params.document_type;

  if (params.document_type !== 'document_type_investigator_profile') {
    deleteParams.operation_param.inspection_id = params.inspection_id;
  }
  return fetchAPI.fetchAPIPOST(url, deleteParams);
}

export function listBundles() {
  const bundleURL = '/inspections/api/payment_bundle?all_bundles=true';
  return fetchAPI.fetchAPIGET(bundleURL);
}

// Contingency:
export function singleBundleDetails(name: string) {
  const bundleURL = `/inspections/api/payment_bundle?single_bundle=true&bundle_name=${name}`;
  return fetchAPI.fetchAPIGET(bundleURL);
}

export function checkoutCart() {
  const checkoutParams = {
    operation: '',
    operation_param: {
      email_address: utilGetLocalStorage('User'),
      // dev:  utilGetLocalStorage('isLocal') === 'true',
    },
  };
  checkoutParams.operation = 'checkout_cart';
  return fetchAPI.fetchAPIPOST(url, checkoutParams);
}

export function purchaseBundle(params: PurchaseBundleParams) {
  const purchaseParams = {
    payment_bundle_name: params.bundleName,
    success_url: `${new URL(window.location.href).href}?queryFromStripe=true`,
    cancel_url: `${new URL(window.location.href).href}?queryFromStripe=false`,
    email_address: utilGetLocalStorage('User'),
    currency_type: utilGetLocalStorage('Country') === 'India' ? 'inr' : 'usd',
    operation: 'init_payment',
    quantity: 1,
  };
  const purchaseURL = '/inspections/api/payment_user_bundle_purchase';
  return fetchAPI.fetchAPIPayments(purchaseURL, purchaseParams);
}

export function paymentUserSubscription() {
  const subsParams = {
    operation: '',
    operation_param: {
      email_address: utilGetLocalStorage('User'),
      // dev:  utilGetLocalStorage('isLocal') === 'true',
    },
  };
  subsParams.operation = 'get_document_list_for_user';
  return fetchAPI.fetchAPIPOST(subscriptionURL, subsParams);
}

export function paymentConfig() {
  const ConfigURL = '/inspections/api/payments/config';
  return fetchAPI.fetchAPIGET(ConfigURL);
}

export function download483(params: downloadParams) {
  return fetchAPI.fetchAPIDownload(constants.download483, params);
}

export function download483r(params: downloadParams) {
  return fetchAPI.fetchAPIDownload(constants.download483r, params);
}

export function downloadeir(params: downloadParams) {
  return fetchAPI.fetchAPIDownload(constants.downloadeir, params);
}
export function downloadmhraEu(params: downloadParams) {
  return fetchAPI.fetchAPIDownload(constants.downloademhraeu, params);
}

export function getSiteDocs(params: any) {
  const SiteDocParams = {
    operation: '',
    operation_param: {
      email_address: utilGetLocalStorage('User'),
      site_id: '',
      // dev:  utilGetLocalStorage('isLocal') === 'true',
    },
  };
  SiteDocParams.operation = 'get_document_list_for_site';
  SiteDocParams.operation_param.site_id = params.site_id;
  return fetchAPI.fetchAPIPOST(subscriptionURL, SiteDocParams);
}

export function getInspectorDocs(params: any) {
  const InspectorDocParams = {
    operation: '',
    operation_param: {
      email_address: utilGetLocalStorage('User'),
      investigator_id: '',
      // dev:  utilGetLocalStorage('isLocal') === 'true',
    },
  };
  InspectorDocParams.operation = 'get_document_list_for_investigator';
  InspectorDocParams.operation_param.investigator_id = params.investigator_id;
  return fetchAPI.fetchAPIPOST(subscriptionURL, InspectorDocParams);
}

export function sendMailNotification(params: any) {
  return fetchAPI.fetchAPIPOST(mailNotifierURL, params)
}
export function sendMailNotificationEIR(params: any) {
  return fetchAPI.fetchAPIPOST(mailNotifierURLRequestEir, params)
}
