import { utilSetLocalStorage, utilGetLocalStorage } from '@/utils/storage_utils';

export function setlocalStorageData() {
  const defaultObj = {
    isThrottling: false,
    quota: {
      download_consumed: 0,
      download_limit: 1,
      details_consumed: 0,
      details_limit: 5,
      details_percent: 0,
    },
    isUpgradeRequested: false,
  };
  // for a test case
  const defaultObjExp = {
    isThrottling: false,
    quota: {
      download_consumed: 1,
      download_limit: 1,
      details_consumed: 5,
      details_limit: 5,
      details_percent: 100,
    },
    isUpgradeRequested: false,
  };

  const json_string: string =
    utilGetLocalStorage('quota') == null ? '{}' : `${utilGetLocalStorage('quota')}`;
  const is_throttled_string: string =
    utilGetLocalStorage('isThrottling') == null
      ? 'false'
      : `${utilGetLocalStorage('isThrottling')}`;
  const is_upgrade_req_string: string =
    utilGetLocalStorage('isUpgradeRequested') == null
      ? 'false'
      : `${utilGetLocalStorage('isUpgradeRequested')}`;
  if (json_string == '{}') return defaultObj;
  const is_throttled: boolean = is_throttled_string.toLowerCase() == 'true';
  const is_upgrade_req: boolean = is_upgrade_req_string.toLowerCase() == 'true';
  const replaced_json_string: string = json_string.replace(/'/g, '"');
  const quota = JSON.parse(replaced_json_string);
  let download_json = {};
  let details_json = {};
  Object.keys(quota).forEach(key => {
    if (key.toLowerCase().endsWith('downloads')) {
      download_json = quota[key];
    } else if (key.toLowerCase().endsWith('details')) {
      details_json = quota[key];
    }
  });
  const download_consumed = download_json.consumed;
  // + is a unary operator which converts string to int
  const download_limit = +download_json.total_rate.split('/')[0];
  const details_consumed = +details_json.consumed;
  // + is a unary operator which converts string to int
  const details_limit = +details_json.total_rate.split('/')[0];
  let details_percent = (details_consumed * 100) / details_limit;
  if (details_percent > 100) details_percent = 100;
  if (details_percent < 0) details_percent = 0;

  const localStorageObj = {
    isThrottling: is_throttled,
    quota: {
      download_consumed,
      download_limit,
      details_consumed,
      details_limit,
      details_percent,
    },
    isUpgradeRequested: is_upgrade_req,
  };

  return localStorageObj || defaultObj;
  // Test env:
  // return defaultObj;
  // return defaultObjExp;
}
