import * as constants from '@/constants';
import * as fetchAPI from '@/components/fetchService';
import { JSONParams } from '@/components/data';

export function getList(params: JSONParams) {
  const url = `${constants.SEOListEP + params.slug_name}.json`;
  return fetchAPI.fetchAPIGETunAuth(url);
}

export function getProfile(params: JSONParams) {
  const url = `${constants.SEOProfileEP + params.slug_name}.json`;
  return fetchAPI.fetchAPIGETunAuth(url);
}

export function getAllMap() {
  const url = `${constants.SEOListEP}all_list_map.json`;
  return fetchAPI.fetchAPIGETunAuth(url);
}
