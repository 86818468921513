import { CartListEP, PaymentBundleParams } from '@/components/data';
import { utilSetLocalStorage, utilGetLocalStorage } from '@/utils/storage_utils';

export function cartManager(cartList: CartListEP) {
  const cartStatus = `${cartList.checkout_status}`;
  const cartIDs: any[] = [];
  cartList.cart_list.forEach((doc: any) => {
    cartIDs.push(doc.document_id);
  });
  const cart = {
    cart: cartIDs,
  };
  const cartSize = `${cartList.cart_list.length}`;
  const cartItems = { cartItems: cartList.cart_list };
  utilSetLocalStorage('cartItems', JSON.stringify(cartItems));
  utilSetLocalStorage('cartLength', cartSize);
  utilSetLocalStorage('checkoutStatus', cartStatus);
  utilSetLocalStorage('cart', JSON.stringify(cart));

  const event: Event = new Event('cartChange');
  window.dispatchEvent(event);
}

export function bundlesManager(bundleList: PaymentBundleParams[]) {
  bundleList.forEach((bundle: any) => {
    bundle.description.maintextArr = bundle.description.main_text.split('.');
  });
  const bundles = { bundles: bundleList };
  utilSetLocalStorage('bundles', JSON.stringify(bundles));
}

export function packageManager(packageDict: any) {
  const packageTemp = { package: packageDict };
  const purchasedIDs: any[] = [];
  const purchasedProfiles: any[] = [];
  packageDict.document_list.forEach((docs: any) => {
    if (docs.document_type === 'document_type_investigator_profile') {
      purchasedProfiles.push(docs.document_id);
    } else purchasedIDs.push(docs.document_id);
  });
  const purchasedDocs = { purchased: purchasedIDs };
  const purchasedInsp = { purchased: purchasedProfiles };
  utilSetLocalStorage('purchasedDocs', JSON.stringify(purchasedDocs));
  utilSetLocalStorage('purchasedProfiles', JSON.stringify(purchasedInsp));
  utilSetLocalStorage('package', JSON.stringify(packageTemp));
}
