import { Reducer } from 'redux';
import { Effect } from 'dva';
import { routerRedux } from 'dva/router';
import { stringify } from 'querystring';
import { notification } from 'antd';
import { getToken, setToken, setAuthority } from '@/utils/authority';
import { reloadAuthorized } from '@/utils/Authorized'

import { utilSetLocalStorage, utilClearLocalStorage } from '@/utils/storage_utils';

import {
  queryCurrent,
  signup as requestSignUp,
  signin as requestSignIn,
  logoutFromServer,
  requestResetPassword,
  requestconfirmResetPassword,
} from '@/services/user';

import { getPageQuery } from '@/utils/utils';
import {
  CartListEP,
  PaymentBundleParams,
  SEOAllMapParams,
  SEOProfileParams,
} from '@/components/data';
import {
  listBundles,
  listCart,
  paymentUserSubscription,
  paymentConfig,
} from '@/components/Pricing/service';
import { getList, getProfile, getAllMap } from '@/pages/Public/service'; // SEO Service
import { cartManager, bundlesManager, packageManager } from '@/components/Pricing/aggregator';

export interface CurrentUser {
  name?: string;
  email?: string;
  company?: string;
  avatar?: string;
}

export interface IFormErrors {
  [key: string]: string[];
}

export interface UserModelState {
  status?: 'ok' | 'error';
  resetPasswordStatus: boolean;
  token?: string | null;
  currentUser?: CurrentUser;
  signInErrors: IFormErrors;
  signUpErrors: IFormErrors;
  passwordResetErrors: IFormErrors;
  cartList: CartListEP;
  bundleList: PaymentBundleParams[];
  package: any;
  public_key: string;
  SEOList: any;
  SEOAllMap: SEOAllMapParams;
  SEOProfile: SEOProfileParams;
}

export interface UserModelType {
  namespace: 'user';
  state: UserModelState;
  effects: {
    signUp: Effect;
    signIn: Effect;
    fetchCurrent: Effect;
    logout: Effect;
    startResetPassword: Effect;
    resetPassword: Effect;
    confirmresetPassword: Effect;
    upgrade: Effect;
    listCart: Effect;
    listBundles: Effect;
    paymentBundle: Effect;
    paymentConfig: Effect;
    // SEO
    getList: Effect;
    getProfie: Effect;
    getAllMap: Effect;
  };
  reducers: {
    saveCurrentUser: Reducer<UserModelState>;
    saveStaus: Reducer<UserModelState>;
    savePasswordResetStatus: Reducer<UserModelState>;
    clearState: Reducer<UserModelState>;
    setSignInErrors: Reducer<UserModelState>;
    setSignUpErrors: Reducer<UserModelState>;
    setPasswordResetErrors: Reducer<UserModelState>;
    upgradeNotif: Reducer<UserModelState>;
    cartItems: Reducer<UserModelState>;
    bundleItems: Reducer<UserModelState>;
    userPackage: Reducer<UserModelState>;
    getPaymentKey: Reducer<UserModelState>;
    // SEO
    saveList: Reducer<UserModelState>;
    saveProfile: Reducer<UserModelState>;
    saveAllMap: Reducer<UserModelState>;
  };
}

const UserModel: UserModelType = {
  namespace: 'user',

  state: {
    status: 'ok',
    resetPasswordStatus: false,
    token: getToken(),
    currentUser: {},
    signInErrors: {},
    signUpErrors: {},
    passwordResetErrors: {},
    cartList: {
      checkout_status: false,
      cart_list: [],
    },
    bundleList: [],
    package: {},
    public_key: '',
    SEOList: {},
    SEOProfile: {
      dbid: 0,
      name: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      duty_station: '',
      organization: '',
      phone_number: '',
      designation: '',
      total_483_issued: 0,
      total_warning_letters_issued: 0,
      longest_inspection: 0,
      shortest_inspection: 0,
      total_number_of_inspections: 0,
      average_length_of_inspection: 0,
      active_locations: [],
      inspection_yearwise_count: {},
      slug_name: '',
    },
    SEOAllMap: {
      categories: {},
      countries: {},
      all_investigators: {},
    },
  },

  effects: {
    *signUp({ payload }, { call, put }) {
      const response = yield call(requestSignUp, payload);
      if (!response.status || response.status < 200 || response.status > 299) {
        const res = yield response.json();
        yield put({
          type: 'setSignUpErrors',
          payload: res,
        });
      } else if (response.status && (response.status >= 200 || response.status <= 299)) {
        const respBody = yield response.json();
        yield put({
          type: 'saveCurrentUser',
          payload: respBody,
        });
        // SignUp Success
        if (respBody.token) {
          setToken(respBody.token);
          const urlParams = new URL(window.location.href);
          const params = getPageQuery();
          let { redirect } = params as { redirect: string };
          if (redirect) {
            const redirectUrlParams = new URL(redirect);
            if (redirectUrlParams.origin === urlParams.origin) {
              redirect = redirect.substr(urlParams.origin.length);
              if (redirect.match(/^\/.*#/)) {
                redirect = redirect.substr(redirect.indexOf('#') + 1);
              }
            } else {
              window.location.href = redirect;
              return;
            }
          }
          yield put(routerRedux.replace(redirect || '/user/login'));
        }
      }
    },
    *signIn({ payload }, { call, put }) {
      const response = yield call(requestSignIn, payload);
      if (response === {} || !response.user) {
        let res = {};
        try {
          res = yield response.json();
        } catch (err) {
          res = { non_field_errors: ['ERROR', 'Enter the correct login credentials!'] };
        }
        yield put({
          type: 'setSignInErrors',
          payload: res,
        });
      } else {
        yield put({
          type: 'saveCurrentUser',
          payload: response,
        });
      }
      // Login successfully
      if (response.token) {
        setToken(response.token);
        const urlParams = new URL(window.location.href);
        const params = getPageQuery();
        let { redirect } = params as { redirect: string };
        if (redirect) {
          const redirectUrlParams = new URL(redirect);
          if (redirectUrlParams.origin === urlParams.origin) {
            redirect = redirect.substr(urlParams.origin.length);
            if (redirect.match(/^\/.*#/)) {
              redirect = redirect.substr(redirect.indexOf('#') + 1);
            }
          } else {
            window.location.href = redirect;
            return;
          }
        }
        // because after login we are not reload site so we have to reload the authority
        reloadAuthorized()
        yield put(routerRedux.replace(redirect || '/'));
      }
    },
    *fetchCurrent(action, { call, put, ...args }) {
      const response = yield call(queryCurrent);
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
    },
    *upgrade(action, { call, put }) {
      const response = yield call();
      yield put({
        type: 'upgradeNotif',
        payload: response,
      });
    },
    *logout(_, { call, put }) {
      // Hit logout endpoint of server
      yield call(logoutFromServer);
      yield put({
        type: 'clearState',
      });
      // redirect
      const { redirect } = getPageQuery();
      if (window.location.pathname !== '/user/login' && !redirect) {
        yield put(
          routerRedux.replace({
            pathname: '/user/login',
            search: stringify({
              redirect: window.location.href,
            }),
          }),
        );
      }
    },
    *startResetPassword(_, { put }) {
      yield put({
        type: 'savePasswordResetStatus',
        payload: false,
      });
    },
    *resetPassword({ payload }, { call, put }) {
      const response = yield call(requestResetPassword, payload);
      if (response.status && response.status !== 200) {
        const res = yield response.json();
        yield put({
          type: 'setPasswordResetErrors',
          payload: res,
        });
        return;
      }
      yield put({
        type: 'savePasswordResetStatus',
        payload: true,
      });
    },
    *confirmresetPassword({ payload }, { call, put }) {
      const response = yield call(requestconfirmResetPassword, payload);

      if (response && response.detail) {
        yield put({
          type: 'savePasswordResetStatus',
          payload: true,
        });
      } else {
        const res = {};
        res.non_field_errors = ['Error in Password Reset, Try Initiating Reset again'];
        yield put({
          type: 'setPasswordResetErrors',
          payload: res,
        });
      }
    },
    *listCart({ payload }, { call, put }) {
      const response = yield call(listCart, payload);
      yield put({
        type: 'cartItems',
        payload: response,
      });
    },
    *listBundles({ payload }, { call, put }) {
      const response = yield call(listBundles, payload);
      yield put({
        type: 'bundleItems',
        payload: response,
      });
    },
    *paymentBundle({ payload }, { call, put }) {
      const response = yield call(paymentUserSubscription, payload);
      yield put({
        type: 'userPackage',
        payload: response,
      });
    },
    *paymentConfig({ payload }, { call, put }) {
      const response = yield call(paymentConfig, payload);
      yield put({
        type: 'getPaymentKey',
        payload: response,
      });
    },
    *getList({ payload }, { call, put }) {
      const response = yield call(getList, payload);
      yield put({
        type: 'saveList',
        payload: response,
      });
    },
    *getProfie({ payload }, { call, put }) {
      const response = yield call(getProfile, payload);
      yield put({
        type: 'saveProfile',
        payload: response,
      });
    },
    *getAllMap({ payload }, { call, put }) {
      const response = yield call(getAllMap, payload);
      yield put({
        type: 'saveAllMap',
        payload: response,
      });
    },
  },

  reducers: {
    getPaymentKey(state, action) {
      const key = action.payload.public_key;
      utilSetLocalStorage('stripeKey', key);
      return {
        ...state!,
        public_key: key,
      };
    },
    saveCurrentUser(state, action) {
      const { token = state!.token } = action.payload;
      utilSetLocalStorage('User', action.payload.user.email);
      utilSetLocalStorage('UserType', action.payload.user.user_type);
      // authority of user is string of the user type
      const userType = action.payload.user.user_type
      setAuthority(userType.toString());
      return {
        ...state!,
        token,
        currentUser: action.payload.user || {},
      };
    },
    upgradeNotif(state, action) {
      // send notif for req sent
      notification.info({
        message: 'Upgrade Request sent',
        description: action.payload ? action.payload : '',
      });
      return { ...state! };
    },
    saveStaus(state, action) {
      return {
        ...state!,
        status: action.payload.status,
      };
    },
    savePasswordResetStatus(state, action) {
      return {
        ...state!,
        resetPasswordStatus: action.payload,
      };
    },
    clearState(state) {
      // Clear Local Data
      utilClearLocalStorage();
      setToken(null);
      return {
        ...state!,
        status: 'ok',
        resetPasswordStatus: false,
        token: null,
        currentUser: {},
        signInErrors: {},
        signUpErrors: {},
        passwordResetErrors: {},
      };
    },
    setSignInErrors(state, action) {
      return {
        ...state!,
        signInErrors: action.payload,
      };
    },
    setSignUpErrors(state, action) {
      return {
        ...state!,
        signUpErrors: action.payload,
      };
    },
    setPasswordResetErrors(state, action) {
      return {
        ...state!,
        passwordResetErrors: action.payload,
      };
    },
    cartItems(state, action) {
      cartManager(action.payload);
      return {
        ...state!,
        cartList: action.payload,
      };
    },
    bundleItems(state, action) {
      bundlesManager(action.payload);
      return {
        ...state!,
        bundleList: action.payload,
      };
    },
    userPackage(state, action) {
      packageManager(action.payload);
      return {
        ...state!,
        package: action.payload,
      };
    },
    saveList(state, action) {
      return {
        ...state!,
        SEOList: action.payload,
      };
    },
    saveProfile(state, action) {
      return {
        ...state!,
        SEOProfile: action.payload,
      };
    },
    saveAllMap(state, action) {
      return {
        ...state!,
        SEOAllMap: action.payload,
      };
    },
  },
};

export default UserModel;
